<template>
  <div id="page">

    <!--例子效果-->
    <vue-particles color="#dedede" :particleOpacity="0.7" :particlesNumber="50" shapeType="circle" :particleSize="4"
                   linesColor="#dedede" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150"
                   :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true"
                   clickMode="push"></vue-particles>

    <!--虚影效果-->
    <div class="shadow-circle"></div>

    <!--头部介绍-->
    <div id="nav-main">
      <div style="height:2px;width:100%;background:#ffa23b"></div>
      <div class="div-flex">
        <div class="logo">
          <img :src="apiUrl+'/'+config['cover']" v-if="config['cover'] && config['cover']!=''" style="height: 35px;">
          <img src="../../static/images/logo.png" v-else style="height: 35px;">
        </div>
        <div style="width: 30px;text-align: center;">
          <img src="../../static/login/images/slogan-line.png">
        </div>
        <div>
          <div style="font-size: 16px;height: 28px;">安全互联<span style="color: #24a7f7;">"网络专家"</span></div>
          <div style="font-size: 13px;height: 24px;">为中国软件事业高效赋能</div>
        </div>
        <div class="nav-space"></div>
        <div style="font-size: 30px;color: #0bbd9b;width: 50px;text-align: center">
          <i class="fa fa-volume-control-phone"></i>
        </div>
        <div style="font-size: 14px;">
          <div style="">热线电话：</div>
          <div style="color: #24a7f7;">{{config['telephone']}}</div>
        </div>
      </div>
    </div>

    <!--表单部分-->
    <div class="form">
      <div class="form-left"></div>
      <div class="form-right">
        <div class="login-title">用户登录</div>
        <div class="login-input"><i class="fa fa-user-o"></i><input placeholder="请输入账号" v-model="form.username"/></div>
        <div class="login-input"><i class="fa fa-key"></i><input type="password" placeholder="请输入密码"
                                                                 v-model="form.password"/></div>
        <div class="login-code">
          <input placeholder="请输入验证码" v-model="form.verify"/>
          <img style="vertical-align:middle;" :src="verifyImg" @click="getVerify()">
        </div>
        <div class="login-input">
          <el-button type="primary" @click="submit()">立即登录</el-button>
        </div>
        <div></div>
        <div></div>
      </div>
    </div>

    <!---版本说明-->
    <div class="version">
      {{config['address']}}
      <!--
      <br><img src="../../static/images/qrcode.png">
      -->
    </div>

    <!--下载-->
    <!--
    <div class="qrcode">
      <el-popover
          placement="bottom"
          trigger="click">
        <img src="../../static/images/qrcode.png" style="width: 200px;">
        <el-button
            slot="reference"
            size="mini"
            type="warning"
            round>
          <i class="fa fa-cloud-download"></i> 下载
        </el-button>
      </el-popover>
    </div>
    -->

  </div>

</template>

<script>
import {setToken} from '@/utils/auth'
import {getConfig, getVerify, login} from '@/api/public'

export default {
  name: "login",
  data() {
    return {

      // 基础网址
      apiUrl: process.env.VUE_APP_BASE_API,

      form: {
        username: '',
        password: '',
        verify: '',
      },

      verifyImg: '',

      // 配置
      config: {},
    }
  },
  created() {

    // 标题
    document.title = "登录";
    this.getConfig();

  },
  methods: {

    // 图形校验码
    getVerify() {
      getVerify().then(res => {
        this.verifyImg = res["data"];
        this.$forceUpdate();
      });
    },

    // 配置文件
    getConfig(){
      getConfig().then(res => {
        this.config = res;
        this.getVerify();
      });
    },

    // 提交
    submit() {
      login(this.form).then(res => {
        if (res.result == true) {
          setToken(res.data.access_token);
          // 页面跳转
          this.$router.push('/index');
        }
      });
    },

  },
}
</script>

<style lang="scss">

html, body, #app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#app {
  background-image: url("../../static/login/images/bg_1.jpg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
}

#page {
  position: relative;
  width: 100%;
  height: 100%;
}

.shadow-circle {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url("../../static/login/images/top-bg.png");
  background-repeat: no-repeat;
  background-position: 0px 70px;
}


#nav-main {
  display: block;
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #FFF;
  border-bottom: 1px solid #e5e5e5;

  font-size: 12px;
  font-family: '微软雅黑', "宋体", "Arial Narrow", Helvetica, sans-serif;
  color: #000;
  line-height: 1.2;
  letter-spacing: 3px;
}

.div-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
}

.div-flex > div {

}

.nav-space {
  width: 30%;
}

@media (max-width: 768px) {
  .div-flex {
    height: 60px;
  }
  .div-flex > div {
    display: none;
  }
  .div-flex > .logo {
    display: block;
  }
  .form-right {
    border-radius: 20px;
    margin: 0px 40px;
  }
  .form-left,.qrcode {
    display: none;
  }
  #app {
    background-image: url("../../static/login/images/bg_2.png");
  }
  .login-title{
    padding-left: 5% !important;
  }
}

.form {
  position: absolute;
  z-index: 3;
  top: 150px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form > div {
  width: 428px;
  height: 395px;
  background-color: #FFF;
}

.form-left {
  background-image: url("../../static/login/images/left_1.png");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.form-right {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}


.login-title {
  font-size: 24px;
  margin-top: 40px;
  padding-left: 50px;
  box-sizing: border-box;
  color: #333333;
  margin-bottom: 40px;
}

.login-input {
  font-size: 20px;
  width: 90%;
  max-width: 320px;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 30px;

  i {
    margin: 6px 10px;
    position: absolute;
    z-index: 10;
    color: #ccc;
  }

  input {
    transition: all 0.5s;
    padding-left: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    color: #333333;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border: 0px;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
    border-radius: 5px;
  }

  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s;
  }

  button {
    width: 100%;
  }
}

.login-code {
  font-size: 20px;
  width: 90%;
  max-width: 320px;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 30px;

  input {
    transition: all 0.5s;
    padding-left: 5px;
    color: #333333;
    width: 120px;
    height: 35px;
    line-height: 35px;
    border: 0px;
    border-bottom: 1px solid #cccccc;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
  }
}

.version {
  position: absolute;
  top: 560px;
  width: 100%;
  text-align: center;
  color: #FFF;
  letter-spacing: 0px;
  font-size: 14px;
  img{
    position: fixed;
    right:10px;
    bottom: 10px;
    width: 200px;
  }
}

.qrcode{
  position:fixed;
  z-index: 12;
  top:25px;
  right: 25px
}

</style>